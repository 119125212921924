.header-container {
  height: 200px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-container header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.clock-container {
  position: absolute;
  top: 50;
  right: 75%;
}

.time-container {
  position: absolute;
  top: 50;
  left: 75%;
}

.title-container {
  margin-bottom: 50px;
  display: flex;
}

.title-container #title {
  font-size: 60px;
  color: var(--primary-2);
}

.title-container h2 {
  font-style: italic;
  font-size: 20px;
}

#img-main {
  height: 150px;
  width: 100%;
  margin-left: 50px;
  transform: translateY(10px);
  display: none;
}

@media (min-width: 992px) {
  #img-main {
    display: block;
  }
}

.time-container {
  display: flex;
}

#time-swap {
  cursor: pointer;
  color: #0492C2;
}

#time-swap:hover {
  color: #63C5DA;
}

.header-container .chatGPT-button {
  cursor: pointer;
  background: none;
  color: #1167b1;
  border: none;
  height: 50px;
  width: 50px;
}

.header-container .chatGPT-button:hover {
  color: darkred;
}

