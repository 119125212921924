*,
::after,
::before {
  box-sizing: border-box;
}


@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
  /* colors */

  /* Primary Scheme: darkest -> lightest */
  --primary-1: #1A374D;
  --primary-2: #406882;
  --primary-3: #6998AB;
  --primary-4: #B1D0E0;
}




.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lora', serif;
}

