.stopwatch-container {
  display: flex;
  flex-direction: column;
  border: 2px darkslategrey solid;
  border-radius: 15px;
  padding: 10px 10px 15px;
  width: 250px;
  height: 155px;
  margin-top: 20px;
}

.stopwatch-container .stopwatch-title {
  display: flex;
  justify-content: center;
}

.stopwatch-container #stopwatch {
  display: flex;
  justify-content: center;
  font-size: 55px;
}

.stopwatch-container .button-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.stopwatch-container .button-container button {
  background: none;
  border: 1px black solid;
  cursor: pointer;
  margin: 5px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 60px;
  height: 30px;
}

