.bounty-outer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding-right: 50px;
  position: relative;
}

.bounty-outer .bounty-button {
  cursor: pointer;
  padding-right: 10px;
  font-size: 40px;  
}
.bounty-outer .star {
  position: absolute;

  /* this percentage/px is the distance 
  from the left side of the window */
  left: 12%;
  /* transform: translate(-200px); */
}

.bounty-outer .star:hover {
  color: orange;
}

.bounty-outer .current-star {
  position: absolute;

  /* this percentage/px is the distance 
  from the left side of the window */
  left: 5%;
  /* transform: translateX(50px); */
}

.bounty-outer .current-star:hover {
  color: slategray;
}



.current-container {
  width: 80%;
  background-color: goldenrod;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 40px;
  padding: 20px;
  border: 2px darkblue solid;
  border-radius: 15px;
  position: relative;
}

.current-container .bounty-task {
  width: 90%;
}

.current-container .bounty-edit {
  width: 85%;
  font-size: 40px;
  margin-left: 10px; 
  overflow-wrap: break-word;
}


.current-container.example {
  display: flex;
  justify-content: center;
  align-items: center;

}


.current-container .bounty-button {
  cursor: pointer;
  padding-right: 10px;
  font-size: 40px;  
}

.bounty-container {
  display: flex;
  justify-content: space-between;
  border: 1px darkslategrey solid;
  border-radius: 15px;
  padding: 20px 20px;
  width: 60%;
}

#double-arrow {
  height: 40px;
  width: 40px;
  color: green;
  position: absolute;
  transform: translate(0px, 0px);
  cursor: pointer;
}
.bounty-container:hover {
  background-color: rgba(0, 0, 0, 0.2);;
}

.bounty {
  display: flex;
  align-items: center;
  /* padding-left: 50px; */
  width: 90%;
}


.bounty .bounty-time {
  padding-right: 20px;
}

.button-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bounty .bounty-button {
  cursor: pointer;
  padding-right: 10px;
  font-size: 40px;
}

.bounty-container .bounty-task {
  width: 85%;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  /* inline-size: 85%; */
}

.bounty-container #bounty-checkbox {
  height: 30px;
  width: 30px;
}


.bounty-container .bounty-edit {
  width: 90%;
  font-size: 30px;
  margin-left: 10px;
}


.bounty-container .bounty-button {
  cursor: pointer;
  padding-right: 10px;
  font-size: 40px; 
}
