.daily-container {
  display: flex;
  align-items: center;
  color: goldenrod;
}

.daily-container *{
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.daily-container.modal-button {
  margin-right: 75px;
}


.modal-daily-award {
  display: flex;
  padding-bottom: 30px;

}



.modal-daily-content {
  color: goldenrod;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.modal-daily-content span {
  margin-left: 5px;
}


.modal-daily-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}