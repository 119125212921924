.info-container .modal-button {
  margin: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
} 

.info-container .modal-button:hover {
  color: darkgreen;
}


/* should cover the entire screen */

.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

 /* actual modal content */
.modal-content {
  background: white;
  width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  border-radius: 10px;
}

.modal-content h1 {
  margin-bottom: 0;
}

.modal-content .modal-divider {
  width: 50%;
  border-top: 2px black solid;
  margin-bottom: 25px;
}


  /* modal image */
.img-modal {
  margin-top: 25px;
  height: 200px;
  width: 100%;
}



.modal-footer {
  margin-top: 50px;
}

.modal-footer .modal-close {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

.modal-footer .modal-close:hover {
  color:darkred;
}