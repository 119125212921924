.bountyList-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}

.bountyList-container form {
  display: flex;
  justify-content: center;
  width: 75%;
  margin-bottom: 30px;
}

.bountyList-container form input {
  font-size: 30px;
  width: 50%;
}

.bountyList-container form button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.bountyList-container form button:hover {
  color: green;
}

    /* Meter */
.meter-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.meter-container h1{
  display: flex;
  align-items: center;
  margin: 0 20px;
}

    /* Images */
#img-emptyCart {
  height: 150%;
  /* width: 150%; */
  margin-top: 50px;
}